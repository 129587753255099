import React from "react"
import {Container} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { Link } from "gatsby"
import "./seo.css"

const SeoServicePage = () => (
  <Layout
    title="Northwest Arkansas SEO Services | Guiding Digital"
    description="Located in Northwest Arkansas, Guiding Digital is available to help you with your search engine optimization (SEO) needs and help you get your business or content discovered by others.">   
    <HeroBanner
      headline="Search Engine Optimization (SEO)"
      className="seoPage"
    />

    <Container>
        <h2 class="text-center">Let Guiding Digital help you share your passion with others.</h2>
        <p>
          You've poured your passion into your content or business, and now it's time to share that passion
          with others.  Contact Guiding Digital and let us help you optimize your content and website for search.
          We offer monthly SEO monitoring plans or can help with a one time optimization effort.
        </p>
        
        <h3>Our Process</h3>

        <p>
          Each engagement is customized to your needs.  We take the time with each project to
          understand your business and goals and develop a SEO plan that will help you advance those goals.
          Our initial engagement will consist of the following phases:
        </p>
        <p>
          <strong>Discovery</strong> - During the discovery phase we seek to understand your business and
          goals for your site and conduct audits to analyze the current effectiveness of your website
          as it relates to search engine optimization.  Audits and research include:
          <ul>
            <li>Technical SEO audit</li>
            <li>On-page SEO audit</li>
            <li>Keyword research</li>
            <li>Content and feature audit</li>
            <li>Competitive analysis</li>
          </ul>
        </p>
        <p>
          <strong>Strategy</strong> - Based on what we found in discovery, we then develop an action plan
          and keyword recommendations that will help achieve your goals.
        </p>
        <p>
          <strong>Execution</strong> - During the execution phase we will implement the items from our
          action plans.
        </p>
        <p>
          <strong>Monitor and Adjust</strong> - At the end of each month we will provide a status report on
          the SEO optimizations and overall site performance and
          provide recommendations on any adjustments that need to be made.
        </p>        

        <h3 className="mt-4">Monthly SEO Monitoring and Maintenance Plans</h3>
        <p>
          Already have a site that is optimized for search?  We offer monthly packages that will make 
          sure it stays optimized.  Guiding Digital will monitor your site's SEO performance and provide
          monthly reporting and recommendations.  Guiding Digital will stay up to date on Google updates
          and your competitors SEO updates and allow you to keep your focus on your business. <Link to="/contact">Contact us</Link> today for a free quote.
        </p>
    </Container>
  </Layout>
)

export default SeoServicePage