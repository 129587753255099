import React from "react"
import './HeroBanner.scss'

const HeroBanner = ({headlineLine1, headline, description, className}) => {
    return (
        <div className={`${className} hero jumbotron`}>
            <div className="innerHero">
                <h1>
                    {headlineLine1 &&
                        <span className="headlineLine1">{headlineLine1}<br /></span>
                    }
                    {headline}
                </h1>
                {description &&
                    <div className="heroDescription">
                        {description}
                    </div>
                }
            </div>
        </div>
    )
}

export default HeroBanner